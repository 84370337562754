import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as ChevronLeftIcon} from '../../Icons/ChevronLeft.svg';
import {ReactComponent as ChevronRightIcon} from '../../Icons/ChevronRight.svg';

const Sidebar = ({theme, handleChangeTab, openLockedModal, allScanners, currentTab}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [scannersNavigation, setScannersNavigation] = useState([]);

  useEffect(() => {
    if (allScanners && allScanners.length) {
      const newNavigation = [
        // {name: 'Dashboard', icon: HomeIcon, href: '/', current: false},
        // {name: 'Room', icon: RoomIcon, href: '#', current: false},
      ];
      allScanners.forEach((s, i) => {
        newNavigation.push({
          name: s.title,
          locked: s.locked,
          live: s.live,
          redirectTo: s?.redirect ?? null,
          type: s?.type,
        });
      });
      setScannersNavigation(newNavigation);
    }
  }, [allScanners, currentTab]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div
      style={{boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.05)'}}
      className={`flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-menu-background   w-1/6 max-w-[200px] sidebar ${
        sidebarOpen ? ' pt-5 pb-4 sidebar-open' : 'sidebar-close pt-1.5 pb-1.5'
      }`}
    >
      <div
        className={`${theme === 'light' ? 'border-[#EBEBEB]' : 'border-[#70819C]'} ${
          sidebarOpen ? 'px-2 pb-4 flex  gap-1' : 'px-1.5 pb-1.5 flex flex-col gap-2'
        } flex flex-shrink-0 items-center  border-b  `}
      >
        {sidebarOpen ? null : (
          <>
            <button
              type="button"
              aria-label="Open Sidebar"
              onClick={() => {
                setSidebarOpen(true);
              }}
            >
              <ChevronRightIcon />
            </button>
            {/* <img src={ScannerIcon} alt="" /> */}
          </>
        )}

        {sidebarOpen ? (
          <>
            <button
              type="button"
              aria-label="Close Sidebar"
              className="close-sidebar-button"
              onClick={() => {
                setSidebarOpen(false);
              }}
            >
              <ChevronLeftIcon />
            </button>
            {/* <img src={ScannerLogo} alt="" /> */}
          </>
        ) : null}
      </div>
      <div
        className={`${theme === 'light' ? 'border-[#EBEBEB]' : 'border-[#70819C]'} ${
          sidebarOpen ? 'px-2' : 'px-1.5 justify-center'
        } mt-4 flex flex-grow flex-col  border-b pb-5 `}
      >
        <nav className={`${sidebarOpen ? 'bg-menu-item' : ''} flex-1 space-y-1 `} aria-label="Sidebar">
          {scannersNavigation.map((item, index) => {
            if (item?.type === 'no-tabs-available') {
              return null;
            }
            if (item?.redirectTo && !item.locked) {
              return (
                <a
                  key={item.name}
                  href={item.redirectTo}
                  type="button"
                  className={classNames(
                    sidebarOpen
                      ? currentTab === index
                        ? 'bg-menu-item-active text-menu-item-text-active border-2 border-menu-item-border-active'
                        : 'text-menu-item-text hover:bg-menu-item-active hover:text-menu-item-text-active  hover:border-menu-item-border-active '
                      : currentTab === index
                      ? 'border-2 border-menu-item-border-active'
                      : 'border-2 border-transparent',
                    'group flex items-center text-xs leading-[15px] leading- font-bold rounded-md text-left outline-none focus:outline-none',
                    sidebarOpen ? 'px-1 py-1.5 w-full' : 'justify-center hover:bg-menu-item-active sidebar-item-wrap w-full',
                  )}
                >
                  {sidebarOpen ? (
                    <span className="flex-1">
                      {item.name}{' '}
                      {item.locked ? (
                        <span>
                          &nbsp;
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      ) : null}
                    </span>
                  ) : item.locked ? (
                    <span
                      className={classNames(
                        sidebarOpen
                          ? currentTab === index
                            ? 'text-white'
                            : 'text-[#9AA2AC] group-hover:text-white'
                          : '',
                        ' flex-shrink-0 h-4 w-4 p-0.5 flex items-center justify-center',
                        sidebarOpen ? 'mr-3' : 'hover:text-white',
                      )}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                  ) : (
                    <span
                      className={classNames(
                        sidebarOpen
                          ? currentTab === index
                            ? 'text-white '
                            : 'text-[#9AA2AC] group-hover:text-white'
                          : '',
                        ' flex-shrink-0 h-4 w-4 p-0.5 flex items-center justify-center',
                        sidebarOpen ? 'mr-3' : 'hover:text-white',
                      )}
                      aria-hidden="true"
                    >
                      {item.name.charAt(0)} {item.name?.includes('Over6') ? <span>6</span> : null}
                    </span>
                  )}
                </a>
              );
            }
            return (
              <button
                key={item.name}
                onClick={() => (item.locked ? openLockedModal() : handleChangeTab(index))}
                type="button"
                className={classNames(
                  sidebarOpen
                    ? currentTab === index
                      ? 'bg-menu-item-active text-menu-item-text-active  border-menu-item-border-active'
                      : 'text-menu-item-text hover:bg-menu-item-active hover:text-menu-item-text-active  hover:border-menu-item-border-active border-transparent'
                    : currentTab === index
                    ? 'border-menu-item-border-active'
                    : 'border-transparent',
                  'group flex items-center text-xs leading-[15px] leading- font-bold rounded-md text-left outline-none focus:outline-none border-2',
                  sidebarOpen ? 'px-1 py-1.5 w-full' : 'justify-center hover:bg-menu-item-active sidebar-item-wrap w-full',
                )}
              >
                {sidebarOpen ? (
                  <span className="flex-1">
                    {item.name}{' '}
                    {item.locked ? (
                      <span>
                        &nbsp;
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                    ) : null}
                  </span>
                ) : item.locked ? (
                  <span
                    className={classNames(
                      sidebarOpen
                        ? currentTab === index
                          ? 'text-white'
                          : 'text-[#9AA2AC] group-hover:text-white'
                        : '',
                      ' flex-shrink-0 h-4 w-4 p-0.5 flex items-center justify-center',
                      sidebarOpen ? 'mr-3' : 'hover:text-white',
                    )}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                ) : (
                  <span
                    className={classNames(
                      sidebarOpen
                        ? currentTab === index
                          ? 'text-white '
                          : 'text-[#9AA2AC] group-hover:text-white'
                        : '',
                      ' flex-shrink-0 h-4 w-4 p-0.5 flex items-center justify-center',
                      sidebarOpen ? 'mr-3' : 'hover:text-white',
                    )}
                    aria-hidden="true"
                  >
                    {item.name.charAt(0)} {item.name?.includes('Over6') ? <span>6</span> : null}
                  </span>
                )}
              </button>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
